// Font colors
export const redFont = { color: 'red' };
export const orangeFont = { color: 'orange' };
export const yellowOrangeFont = { color: '#FFCC00' };
export const yellowFont = { color: 'yellow' };
export const chartreuseFont = { color: '#80FF00' };
export const greenFont = { color: 'green' };
export const forestGreenFont = { color: "#228B22" };
export const cyanFont = { color: 'cyan' };
export const blueFont = { color: 'blue' };
export const oceanBlueFont = { color: '#0077be' };
export const blueVioletFont = { color: '#8a2be2' };
export const purpleFont = { color: 'purple' };