import React from 'react';

import useUserInfo from 'hook/useUserInfo';
import { redFont, orangeFont, cyanFont, oceanBlueFont, chartreuseFont, greenFont, forestGreenFont, blueFont, purpleFont, blueVioletFont, yellowOrangeFont } from 'styles';

export default function DefinitionPage() {
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }
    return <>
        <h1>Harvest IDs</h1>
        <h2>
            <span style={{ ...redFont }} >YYYY</span>
            /
            <span style={{ ...orangeFont }}>MM</span>
            /
            <span style={{ ...yellowOrangeFont }}>DD</span>
            .
            <span style={{ ...chartreuseFont }}>CCC</span>
            -
            <span style={{ ...greenFont }}>T</span>
            -
            <span style={{ ...forestGreenFont }}>NNN</span>
            .
            <span style={{ ...cyanFont }}>E</span>
            -
            <span style={{ ...blueFont }}>III</span>
            -
            <span style={{ ...oceanBlueFont }}>GGG</span>
            -
            <span style={{ ...blueVioletFont }}>OO</span>
            .
            <span style={{ ...purpleFont }}>SSSSS</span>
        </h2>
        <ul>
            <li>
                YYYY - Year the harvest started
                </li>
            <li>
                MM - Month the harvest started
                </li>
            <li>
                DD - Day the harvest started
                </li>
            <li>
                C (alpha string) – City Abbr where harvest resides
                </li>
            <li>
                T (enum) - City Type (F – farm, L – lab, H – home, S – seed)
                </li>
            <li>
                N (int) - Building #
                </li>
            <li>
                E (enum) – Crop Type (0 – food, 1 – feed, 2 – fiber, 3 – oil, 4 – ornamental, 5 – inustrial)
                </li>
            <li>
                I (alpha numeric string) – Crop ID
                </li>
            <li>
                G (alpha numeric string) - Crop species ID
                </li>
            <li>
                O (alpha numeric string) - Crop origin ID
                </li>
            <li>
                S (alpha numeric string) - serial ID
                </li>
        </ul>

        <h1>Seed Inventory IDs</h1>
        <h2>
            <span style={{ ...redFont }} >YYYY</span>
            /
            <span style={{ ...orangeFont }}>MM</span>
            /
            <span style={{ ...yellowOrangeFont }}>DD</span>
            .
            <span style={{ ...chartreuseFont }}>CCC</span>
            -
            <span style={{ ...greenFont }}>T</span>
            -
            <span style={{ ...forestGreenFont }}>NNN</span>
            .
            <span style={{ ...cyanFont }}>E</span>
            -
            <span style={{ ...blueFont }}>III</span>
            -
            <span style={{ ...oceanBlueFont }}>GGG</span>
            -
            <span style={{ ...blueVioletFont }}>OO</span>
            .
            <span style={{ ...purpleFont }}>SSSSS</span>
        </h2>
        <ul>
            <li>
                YYYY - Year the seed was harvested/purchased
                </li>
            <li>
                MM - Month the seed was harvested/purchased
                </li>
            <li>
                DD - Day the seed was harvested/purchased
                </li>
            <li>
                C (alpha string) – City Abbr where seed was harvested/purchased
                </li>
            <li>
                T (enum) - City Type (F – farm, L – lab, H – home, S – seed, E - external)
                </li>
            <li>
                N (int) - Building #
                </li>
            <li>
                E (enum) – Crop Type (0 – food, 1 – feed, 2 – fiber, 3 – oil, 4 – ornamental, 5 – inustrial)
                </li>
            <li>
                I (alpha numeric string) – Crop ID
                </li>
            <li>
                G (alpha numeric string) - Crop species ID
                </li>
            <li>
                O (alpha numeric string) - Crop origin ID
                </li>
            <li>
                S (alpha numeric string) - serial ID
                </li>
        </ul>

        <h1>Comb IDs</h1>

        <h2>
            <span style={{ ...redFont }} >YYYY</span>
            /
            <span style={{ ...orangeFont }}>MM</span
            >/
            <span style={{ ...yellowOrangeFont }}>DD</span>
            .
            <span style={{ ...chartreuseFont }}>CCC</span>
            -
            <span style={{ ...greenFont }}>T</span>
            -
            <span style={{ ...forestGreenFont }}>NNN</span>
            .
            <span style={{ ...blueFont }}>ZZ</span>
            .
            <span style={{ ...purpleFont }}>SSSSS</span>
        </h2>
        <ul>
            <li>
                YYYY - Year the comb was created
                </li>
            <li>
                MM - Month the comb was created
                </li>
            <li>
                DD - Day the comb was created
                </li>
            <li>
                C (alpha string) – City Abbr where comb originally resides
                </li>
            <li>
                T (enum) - City Type (F – farm, L – lab, H – home, S – seed)
                </li>
            <li>
                N (int) - Building #
                </li>
            <li>
                Z (alpha numeric string) – Comb type
                </li>
            <li>
                S (alpha numeric string) - serial ID
                </li>
        </ul>
        <h1>Inventory IDs</h1>
        <h2>
            <span style={{ ...redFont }} >YYYY</span>
            /
            <span style={{ ...orangeFont }}>MM</span
            >/
            <span style={{ ...yellowOrangeFont }}>DD</span>
            .
            <span style={{ ...chartreuseFont }}>CCC</span>
            -
            <span style={{ ...greenFont }}>T</span>
            -
            <span style={{ ...forestGreenFont }}>NNN</span>
            .
            <span style={{ ...blueFont }}>JJJ</span>
            .
            <span style={{ ...purpleFont }}>SSSSS</span>
        </h2>
        <ul>
            <li>
                Y (int) – year it was purchased/created
                </li>
            <li>
                M (int) – month it was purchased/created
                </li>
            <li>
                D (int) – day it was purchased/created
                </li>
            <li>
                C (alpha string) – City Abbr where product originally resides
                </li>
            <li>
                T (enum) - City Type (F – farm, L – lab, H – home, S – seed)
                </li>
            <li>
                N (int) - Building #
                </li>
            <li>
                J (alpha numeric string) - product type (000 - tray w/ holes, 001 - tray w/o holes)
                </li>
            <li>
                S (alpha numeric string) - serial ID
                </li>
        </ul>
        <h1>Sensor IDs</h1>
        <h2>
            <span style={{ ...redFont }} >YYYY</span>
            /
            <span style={{ ...orangeFont }}>MM</span
            >/
            <span style={{ ...yellowOrangeFont }}>DD</span>
            .
            <span style={{ ...chartreuseFont }}>CCC</span>
            -
            <span style={{ ...greenFont }}>T</span>
            -
            <span style={{ ...forestGreenFont }}>NNN</span>
            .
            <span style={{ ...blueFont }}>Q</span>
            -
            <span style={{ ...blueFont }}>H</span>
            -
            <span style={{ ...blueFont }}>P</span>
            -
            <span style={{ ...blueFont }}>G</span>
            -
            <span style={{ ...blueFont }}>U</span>
            -
            <span style={{ ...blueFont }}>L</span>
            -
            <span style={{ ...blueFont }}>KKKKKKKKKK</span>
            .
            <span style={{ ...purpleFont }}>SSSSS</span>
        </h2>
        <ul>
            <li>
                Y (int) – year it was created
                </li>
            <li>
                M (int) – month it was created
                </li>
            <li>
                D (int) – day it was created
                </li>
            <li>
                C (alpha string) – City Abbr where product was created
                </li>
            <li>
                T (enum) - City Type (F – farm, L – lab, H – home, S – seed)
                </li>
            <li>
                N (int) - Building #
                </li>
            <li>
                Q - 1 if records temporature else 0
                </li>
            <li>
                H - 1 if records humidity else 0
                </li>
            <li>
                P - 1 if records pressure else 0
                </li>
            <li>
                G - 1 if records gas else 0
                </li>
            <li>
                U - 1 if records UV else 0
                </li>
            <li>
                L - 1 if records light else 0
                </li>
            <li>
                K - unused sensor codes
                </li>
            <li>
                S (alpha numeric string) - serial ID
                </li>
        </ul>
    </>;
}