import React, { useState } from 'react';

import { useHistory } from "react-router-dom";
import BaseModal from 'modal/BaseModal.react';
import useURLParam from 'hook/useURLParam';
import { create } from 'graphql/HarvestMutation';
import { stringToTime } from 'util/Date';
import { TextField, Select, InputLabel, FormControl, Button } from '@material-ui/core';

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
export default function CreateHarvestModal() {
    const history = useHistory();
    const { getParam, updateParam } = useURLParam();
    const harvestParam = getParam('create_harvest');

    const [values, setValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const validData = values.office != null && values.crop != null && values.plant_date != null;

    const close = () => updateParam('create_harvest', null);
    return harvestParam == null ? null : (
        <BaseModal
            isOpen={harvestParam != null}
            onClose={close}
        >
            <FormControl>
                <InputLabel htmlFor="office">Office</InputLabel>
                <Select id="office" native onChange={(event) => setValues({ ...values, office: event.target.value })}
                    value={values.office}>
                    <option value={null} />
                    <option value={"SAF-H-001"}>SAF-H-001 (932 Felton - Deanne's Parents)</option>
                    <option value={"SAF-H-002"}>SAF-H-002 (239 Seneca - Deanne's)</option>
                    <option value={"BEL-H-001"}>BEL-H-001 (419 Chesteron - Stanfill's)</option>
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel htmlFor="crop">Crop</InputLabel>
                <Select id="crop" native onChange={(event) => setValues({ ...values, crop: event.target.value })}
                    value={values.crop}>
                    <option value={null} />
                    <option value={"0-001-001-00"}>0-001-001-00: Pea/Pisum sativum/TrueLeafMarket</option>
                </Select>
            </FormControl>
            <div>
                <TextField id="observation" type="text" helperText="Observation" onChange={(event) => setValues({ ...values, observation: event.target.value })} />
            </div>
            <div>
                <TextField id="quandry" type="text" helperText="Quandry (Who, What, When, Where, Why, How)" onChange={(event) => setValues({ ...values, quandry: event.target.value })} />
            </div>
            <div>
                <TextField id="hypothesis" type="text" helperText="Hypothesis" onChange={(event) => setValues({ ...values, hypothesis: event.target.value })} />
            </div>
            <div>
                <TextField id="prediction" type="text" helperText="Prediction" onChange={(event) => setValues({ ...values, prediction: event.target.value })} />
            </div>
            <div>
                <TextField id="plant_date" type="date" helperText="Plant Date" onChange={(event) => setValues({ ...values, plant_date: stringToTime(event.target.value) })} />
            </div>
            <div>
                <TextField id="harvest_date" type="date" helperText="Harvest Date" onChange={(event) => setValues({ ...values, harvest_date: stringToTime(event.target.value) })} />
            </div>
            <Button variant="contained" onClick={close}>Close</Button>
            <Button variant="contained" color="primary" disabled={!validData || isLoading}
                onClick={() => {
                    setIsLoading(true);
                    create({
                        ...values,
                        city: values.office.split('-')[0],
                        city_type: values.office.split('-')[1],
                        building_number: parseInt(values.office.split('-')[2]),
                        crop_type: parseInt(values.crop.split('-')[0]),
                        crop_id: values.crop.split('-')[1],
                        crop_species: values.crop.split('-')[2],
                        crop_origin: values.crop.split('-')[3],
                    }, (harvest) => {
                        setIsLoading(false);
                        close();
                        const params = new URLSearchParams(history.location.search);
                        history.push({
                            pathname: `/harvests/${harvest.guid}`,
                            search: params.toString(),
                        });
                    }); // TODO link to return
                }}
            >
                Submit
            </Button>
        </BaseModal >
    );
}
