import React from 'react';

import useUserInfo from 'hook/useUserInfo';
import UsersList from 'user/UsersList.react';

export default function UserDirectoryPage() {
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }
    return <>
        <UsersList />
    </>;
}