import React from 'react';
import { GoogleLogin } from 'react-google-login';

import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { graphMutation } from 'graphql/Client';
import useUserInfo from 'hook/useUserInfo';

const query = `
mutation Login_Mutation($g_id: String, $name: String, $email: String, $pic: String) {
    login(g_id: $g_id, name: $name, email: $email, pic: $pic) {
        g_id
        name
        pic
        email
    }
}
`;

export default function GLogin() {
    const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const [_, updateUser] = useUserInfo();

    return (
        <GoogleLogin
            render={({ onClick }) =>
                <Tooltip title="Sign in with Google">
                    <IconButton
                        onClick={onClick}
                    >
                        < AccountCircle
                            color="primary" fontSize="large" style={{ color: '#ffffff' }} />
                    </IconButton>
                </Tooltip>
            }
            clientId={clientID}
            onSuccess={(res) => {
                const user = {
                    pic: res.profileObj?.imageUrl,
                    name: res.profileObj?.name,
                    email: res.profileObj?.email,
                    g_id: res.profileObj?.googleId,
                };
                graphMutation(query, { ...user });
                updateUser(user);
            }
            }
            onFailure={(res) => console.log('Login Failure', res)}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
        />
    );
}
