import { useContext } from 'react';
import Context from 'Context.react';

export default function useUserInfo() {
    const [context, updateContext] = useContext(Context);
    const { user } = context;
    return [{
        isLoggedIn: user != null,
        isEmployee: (user?.email ?? '').endsWith('xcelsi.org'),
        name: user?.name,
        email: user?.email,
    }, (user) => {
        updateContext({ ...context, user });
    }]
}