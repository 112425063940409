import React, { useContext } from 'react';
import Context from 'Context.react';

import Avatar from '@material-ui/core/Avatar';

export default function UserIcon() {
    const [{ user }] = useContext(Context);
    if (user == null) {
        return null;
    }
    return <Avatar alt={user.name} src={user.pic} />
}