import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

// https://material-ui.com/components/modal/#modal
export default function BaseModal({ isOpen, onClose, children }) {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <div style={modalStyle} className={classes.paper}>{children}</div>
        </Modal>
    );
}
