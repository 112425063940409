import React from 'react';

import {
    Link,
} from 'react-router-dom';

import useUserInfo from 'hook/useUserInfo';

export default function Sidebar() {
    const [{ isEmployee }] = useUserInfo();
    const employeeNavs = isEmployee ? <>
        <div>
            <Link to="/buildings"><h1>Building Directory</h1></Link>
        </div>
        <div>
            <Link to="/users"><h1>User Directory</h1></Link>
        </div>
        <div>
            <Link to="/plants"><h1>Plant Directory</h1></Link>
        </div>
        <div>
            <Link to="/definitions"><h1>Definitions</h1></Link>
        </div>
        <div>
            <Link to="/harvests"><h1>Harvests</h1></Link>
        </div>
        <div>
            <Link to="/sensor_data"><h1>Sensor Data</h1></Link>
        </div>
    </> : null;
    return (
        <>
            <div>
                <Link to="/"><h1>Home</h1></Link>
            </div>
            {employeeNavs}
            <hr />{/* Remove when this menu is put into the bar */}
        </>
    );
}
