import React, { useState, useEffect } from 'react';
import { graphQuery } from 'graphql/Client';
import Avatar from '@material-ui/core/Avatar';

const query = `
query UsersList_Query {
    getUsers {
        id
        name
        pic
        email
    }
}
`;

export default function UsersList() {
    const [users, setUsers] = useState([]);
    useEffect(
        () => {
            graphQuery(query).then(data => setUsers(data?.getUsers ?? []));
        },
        [query, setUsers],
    );

    return <>
        {
            users.map(user =>
                <div
                    key={user.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                    <Avatar alt={user.name} src={user.pic} />
                    <span>
                        <div>{user.name}</div>
                        <div>{user.email}</div>
                    </span>
                </div>)
        }
    </>
}