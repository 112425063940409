import React, { useEffect, useState } from 'react';

import { graphQuery } from 'graphql/Client';
import { useParams } from "react-router-dom";
import { stringToTime } from 'util/Date';
import { update } from 'graphql/HarvestMutation';
import { TextField, Select, InputLabel, FormControl, Button } from '@material-ui/core';

const QUERY = `
query HarvestPage_Query($guid: String) {
    getHarvest(guid: $guid) {
        id
        guid
        city
        city_type
        building_number
        crop_type
        crop_id
        crop_species
        crop_origin
        plant_date
        harvest_date
        observation
        quandry
        hypothesis
        prediction
    }
}
`;

export default function DefaultView() {
    const { guid } = useParams();
    const [harvest, setHarvest] = useState(null);
    const query = (onFinish) => {
        if (guid != null) {
            graphQuery(QUERY, { guid }).then(data => {
                onFinish && onFinish();
                setHarvest(data?.getHarvest);
            });
        }
    }
    useEffect(
        query,
        [guid],
    );
    const onUpdate = (params, onFinish) => {
        if (harvest == null) {
            return;
        }
        update({ ...params, id: harvest.id }, () => {
            query(onFinish);
        });
    }
    return <>
        <h1>{harvest?.id}</h1>
        <Setup harvest={harvest} onUpdate={onUpdate} />
        <Location harvest={harvest} />
        <Crop harvest={harvest} />
    </>;
}

function Setup({ harvest, onUpdate }) {
    if (harvest == null) {
        return null;
    }
    return <table>
        <thead>
            <tr>
                <th>Setup</th>
            </tr>
        </thead>
        <tbody>
            <Row label="Observation" type="text" value={harvest.observation} onChange={(value, onFinish) => onUpdate({ observation: value }, onFinish)} />
            <Row label="Quandry" type="text" value={harvest.quandry} onChange={(value, onFinish) => onUpdate({ quandry: value }, onFinish)} />
            <Row label="Hypothesis" type="text" value={harvest.hypothesis} onChange={(value, onFinish) => onUpdate({ hypothesis: value }, onFinish)} />
            <Row label="Prediction" type="text" value={harvest.prediction} onChange={(value, onFinish) => onUpdate({ prediction: value }, onFinish)} />
            <Row label="Plant Date" type="date" value={harvest.plant_date} onChange={(value, onFinish) => onUpdate({ plant_date: value }, onFinish)} />
            <Row label="Harvest Date" type="date" value={harvest.harvest_date} onChange={(value, onFinish) => onUpdate({ harvest_date: value }, onFinish)} />
        </tbody>
    </table>
}

function Location({ harvest }) {
    if (harvest == null) {
        return null;
    }
    return <table>
        <thead>
            <tr>
                <th>Location</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>City</td>
                <td>{harvest.city}</td>
            </tr>
            <tr>
                <td>City Type</td>
                <td>{harvest.city_type}</td>
            </tr>
            <tr>
                <td>Building Number</td>
                <td>{('000' + harvest.building_number).slice('-3')}</td>
            </tr>
        </tbody>
    </table>
}

function Crop({ harvest }) {
    if (harvest == null) {
        return null;
    }
    return <table>
        <thead>
            <tr>
                <th>Crop</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Crop Type</td>
                <td>{harvest.crop_type}</td>
            </tr>
            <tr>
                <td>Crop ID</td>
                <td>{harvest.crop_id}</td>
            </tr>
            <tr>
                <td>Crop Species</td>
                <td>{harvest.crop_species}</td>
            </tr>
            <tr>
                <td>Crop Origin</td>
                <td>{harvest.crop_origin}</td>
            </tr>
        </tbody>
    </table>
}

function Row({ label, type, value, onChange }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [updatedValue, setUpdatedValue] = useState(value);

    useEffect(() => { setUpdatedValue(value) }, [value]);

    const content = isSaving ? "INSERT SPINNER" : isEditing
        ? (<TextField
            type={type}
            onChange={(event) =>
                setUpdatedValue(type == 'date' ? stringToTime(event.target.value) : event.target.value)} />)
        : type == 'date'
            ? (updatedValue == null
                ? null
                : new Date(updatedValue).toString())
            : updatedValue;
    const buttons = isEditing ? <>
        <Button
            variant="contained"
            onClick={() => {
                setIsEditing(false);
                setUpdatedValue(value);
            }}>
            Cancel
            </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={() => {
                setIsSaving(true);
                setIsEditing(false);
                onChange(updatedValue, () => {
                    setUpdatedValue(value);
                    setIsSaving(false);
                });
            }}>
            Save
            </Button>
    </> :
        <Button variant="contained" onClick={() => setIsEditing(true)}>Edit</Button>;

    return <tr>
        <td>{label}</td>
        <td>
            {content}
        </td>
        <td>
            {buttons}
        </td>
    </tr>
}