import React, { useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

import Sidebar from 'layout/Sidebar.react';
import Context from 'Context.react';
import XAppBar from 'layout/XAppBar.react';
import HomePage from 'page/HomePage.react';
import DefinitionPage from 'page/DefinitionPage.react';
import HarvestPage from 'page/harvest/HarvestPage.react';
import PlantsPage from 'page/PlantsPage.react';
import BuildingDirectory from 'page/BuildingDirectory.react';
import UserDirectoryPage from 'page/UserDirectoryPage.react';
import SensorDataPage from 'page/SensorDataPage.react';

export default function App() {
  const context = useState({});

  return (
    <Context.Provider value={context}>
      <BrowserRouter>
        <XAppBar />
        <Sidebar />
        <Switch>
          <Route path="/" exact={true} component={HomePage} />
          <Route path="/buildings" component={BuildingDirectory} />
          <Route path="/users" component={UserDirectoryPage} />
          <Route path="/definitions" component={DefinitionPage} />
          <Route path="/harvests" component={HarvestPage} />
          <Route path="/plants" component={PlantsPage} />
          <Route path="/sensor_data" component={SensorDataPage} />
        </Switch>
      </BrowserRouter>
    </Context.Provider>
  );
}
