import React, { useContext } from 'react';
import Context from 'Context.react';

export default function UserView() {
    const [{ user }] = useContext(Context);
    if (user == null) {
        return null;
    }
    return <div>
        <img src={user.pic} alt="User Profile" />
        <div>User Name: {user.name}</div>
        <div>Email: {user.email}</div>
        <div>Google ID: {user.g_id}</div>
    </div>
}