import React from 'react';

import useUserInfo from 'hook/useUserInfo';

export default function BuildingDirectory() {
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }
    return <>
        <h1>SAF-H-001</h1>
        <h2>
            <a
                href="https://www.google.com/maps/place/932+Felton+St,+San+Francisco,+CA+94134"
                target="_blank">
                932 Felton St, 94134
        </a>
        </h2>
        <h1>SAF-H-002</h1>
        <h2>
            <a
                href="https://www.google.com/maps/place/239+Seneca+Ave,+San+Francisco,+CA+94112"
                target="_blank">
                239 Seneca Ave, 94112
            </a>
        </h2>
        <h1>BEL-H-001</h1>
        <h2>
            <a
                href="https://www.google.com/maps/place/419+Chesterton+Ave,+Belmont,+CA+94002"
                target="_blank">
                419 Chesterton Ave, 94002
            </a>
        </h2>
    </>;
}