
import { graphMutation } from 'graphql/Client';

const CREATE_MUTATION = `
mutation CreateHarvest_Mutation(
        $plant_date: Date, 
        $city: String, 
        $city_type: String, 
        $building_number: Int, 
        $crop_type: Int,
        $crop_id: String, 
        $crop_species: String, 
        $crop_origin: String, 
        $observation: String, 
        $quandry: String, 
        $hypothesis: String, 
        $prediction: String, 
        $harvest_date: Date
    ) {
    createHarvest(
            plant_date: $plant_date,
            city: $city,
            city_type: $city_type,
            building_number: $building_number,
            crop_type: $crop_type,
            crop_id: $crop_id,
            crop_species: $crop_species,
            crop_origin: $crop_origin,
            observation: $observation,
            quandry: $quandry,
            hypothesis: $hypothesis,
            prediction: $prediction,
            harvest_date: $harvest_date,
        ) {
        id
        guid
    }
}
`;

const UPDATE_MUTATION = `
mutation UpdateHarvest_Mutation(
        $id: String,
        $plant_date: Date, 
        $city: String, 
        $city_type: String, 
        $building_number: Int, 
        $crop_type: Int,
        $crop_id: String, 
        $crop_species: String, 
        $crop_origin: String, 
        $observation: String, 
        $quandry: String, 
        $hypothesis: String, 
        $prediction: String, 
        $harvest_date: Date
    ) {
    updateHarvest(
            id: $id,
            plant_date: $plant_date,
            city: $city,
            city_type: $city_type,
            building_number: $building_number,
            crop_type: $crop_type,
            crop_id: $crop_id,
            crop_species: $crop_species,
            crop_origin: $crop_origin,
            observation: $observation,
            quandry: $quandry,
            hypothesis: $hypothesis,
            prediction: $prediction,
            harvest_date: $harvest_date,
        ) {
        id
        guid
    }
}
`;

export const create = (params, onFinish) => {
    graphMutation(CREATE_MUTATION, { ...params }, (data) => onFinish && onFinish(data.createHarvest))
}

export const update = (params, onFinish) => {
    if (params.id == null) {
        throw "MISSING REQUIRED FIELD ID";
    }
    graphMutation(UPDATE_MUTATION, { ...params }, (data) => onFinish && onFinish(data.updateHarvest))
}