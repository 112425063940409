import React, { useEffect, useState } from 'react';

import {
    Route,
    Switch
} from 'react-router-dom';

import useUserInfo from 'hook/useUserInfo';
import { graphQuery } from 'graphql/Client';
import DefaultView from './view/DefaultView.react';
import HarvestView from './view/HarvestView.react';

const query = `
query HarvestPage_Query {
    getHarvests {
        id
        guid
    }
}
`;

export default function HarvestPage() {
    const [harvests, setHarvests] = useState([]);
    useEffect(
        () => {
            graphQuery(query).then(data => setHarvests(data?.getHarvests ?? []));
        },
        [query, setHarvests],
    );
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }
    return <>
        <Switch>
            <Route path="/harvests/" exact={true} component={DefaultView} />
            <Route path="/harvests/:guid" component={HarvestView} />
        </Switch>
    </>;
}