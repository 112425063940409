export function graphMutation(query, variables, onFinish) {
    fetch('/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        })
    })
        .then(r => r.json())
        .then(resp => onFinish && onFinish(resp?.data));
}

export function graphQuery(query, variables) {
    return fetch('/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        })
    })
        .then(r => r.json())
        .then(resp => resp?.data);
}