import React, { useEffect, useState } from 'react';

import useUserInfo from 'hook/useUserInfo';
import { graphQuery } from 'graphql/Client';

const query = `
query PlantsPage_Query {
    getPlants {
        id
        name
        species {
            id
            name
            origins {
              id
              name
              home_url
              url
            }
        }
    }
}
`;

export default function PlantsPage() {
    const [plants, setPlants] = useState([]);
    useEffect(
        () => {
            graphQuery(query).then(data => setPlants(data?.getPlants ?? []));
        },
        [query, setPlants],
    );
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }

    return <>
        {plants.map(plant =>
            <>
                <h1 key={plant.id}>{plant.id} - {plant.name}</h1>
                {plant.species.map(species => {
                    return (
                        <>
                            <h2 key={species.id}>{species.id} - {species.name}</h2>
                            {species.origins.map(origin => <h3 key={origin.id}>{origin.id} - {origin.name}: <a href={origin.home_url} target="_blank">Home Page</a> - <a href={origin.url} target="_blank">Purchase Page</a></h3>)}
                        </>
                    );
                })
                }
            </>)}
    </>;
}