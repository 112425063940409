import React, { useEffect, useState } from 'react';

import {
    Link,
} from 'react-router-dom';

import CreateHarvestModal from 'modal/CreateHarvestModal.react';
import { graphQuery } from 'graphql/Client';
import UrlModalButton from 'button/UrlModalButton.react';

const query = `
query HarvestPage_Query {
    getHarvests {
        id
        guid
    }
}
`;

export default function DefaultView() {
    const [harvests, setHarvests] = useState([]);
    useEffect(
        () => {
            graphQuery(query).then(data => setHarvests(data?.getHarvests ?? []));
        },
        [query, setHarvests],
    );

    return <>
        <UrlModalButton param="create_harvest" label="Create Harvest" />
        {harvests.map(harvest =>
            <Link to={`/harvests/${harvest.guid}`}>
                <h1 key={harvest.id}>{harvest.id}
                </h1>
            </Link>)}
        <CreateHarvestModal />
    </>;
}