import React, { useEffect, useState } from 'react';

import useUserInfo from 'hook/useUserInfo';
import { graphQuery } from 'graphql/Client';

const query = `
query SensorDataPage_Query {
    getSensorData {
        sensor_id
        datetime
        temp
        temp_scale
    }
}
`;

export default function SensorDataPage() {
    const [sensorData, setSensorData] = useState([]);
    useEffect(
        () => {
            graphQuery(query).then(data => setSensorData(data?.getSensorData ?? []));
        },
        [query, setSensorData],
    );
    const [{ isLoggedIn, isEmployee }] = useUserInfo();
    if (!isLoggedIn) {
        return <div>Please Login</div>
    }
    if (!isEmployee) {
        return <div>For employees eyes only</div>
    }

    return <>
        {sensorData.map(data =>
            <div style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h1 style={{ display: 'inline' }}>
                    {`${new Date(data.datetime).toLocaleDateString()} ${new Date(data.datetime).toLocaleTimeString()}`}
                </h1>
                <h2 style={{ display: 'inline' }}>ID: {data.sensor_id}</h2>
                <h2 style={{ display: 'inline' }}>Temp: {`${data.temp}°${data.temp_scale}`}</h2>
            </div>)
        }
    </>;
}