import { useHistory } from "react-router-dom";

export default function useURLParam() {
    const history = useHistory();
    return {
        getParam: (key) => {
            const params = new URLSearchParams(history.location.search);
            return params.get(key)
        },
        updateParam: (key, value) => {
            const params = new URLSearchParams(history.location.search);
            if (value == null) {
                params.delete(key);
            } else {
                params.set(key, value);
            }
            history.push({
                pathname: history.location.pathname,
                search: params.toString(),
            });
        }
    };
}